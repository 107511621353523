Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpPostMethod = "POST";
exports.httpGetMethod = "GET";
exports.httpDeleteMethod = "DELETE";
exports.jsonContentType = "application/json";

exports.usersScreenHeading = "Select a user";

exports.searchPlaceholder = "Search";
exports.placeholderTextColor = "grey";
exports.backgroundColor = "white";
exports.textColor = "black";
exports.goBack = "Go Back";
exports.getUsersEndpoint = "bx_block_savesearch/users";
exports.getSearchEndpoint = "bx_block_savesearch/data_searches"
exports.saveSearchEndpoint = "bx_block_savesearch/data_searches"
exports.deleteSearchEndpoint = "bx_block_savesearch/data_searches"
// Customizable Area End