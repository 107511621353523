import React from "react";

// Customizable Area Start
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// Customizable Area End

import SavesearchController, {
  Props,
  configJSON,
} from "./SavesearchController";

export default class Savesearch extends SavesearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isLoading, showSearch, searchQuery, users, searches } = this.state;

    if (isLoading) {
      return (
        <div style={webStyle.loadingContainer}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <Container maxWidth={"sm"} style={webStyle.mainWrapper}>
        {showSearch ? (
          <>
            <Button
              data-test-id="navigateBackButton"
              startIcon={<ArrowBackIcon />}
              onClick={this.onNavigateBack}
            >
              {configJSON.goBack}
            </Button>
            <div style={webStyle.searchContainer}>
              <TextField
                variant="outlined"
                size="small"
                placeholder={configJSON.searchPlaceholder}
                fullWidth={true}
                style={webStyle.inputStyle}
                value={searchQuery}
                onChange={(event) =>
                  this.onChangeSearchQuery(event.target.value)
                }
              />
              <IconButton
                onClick={this.saveSearch}
                disabled={searchQuery.length === 0}
              >
                <AddIcon />
              </IconButton>
            </div>
            <ul style={webStyle.list} data-test-id="searches">
              {searches.length > 0 &&
                searches.map((search) => (
                  <li
                    key={search.id}
                    style={{ ...webStyle.listItem, ...webStyle.searchItem }}
                  >
                    <Typography>{search.name}</Typography>
                    <IconButton
                      data-test-id="deleteSearchButton"
                      style={webStyle.clearButton}
                      onClick={() => this.deleteSearch(search.name)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </li>
                ))}
            </ul>
          </>
        ) : (
          <>
            <Typography variant="h5" gutterBottom>
              {configJSON.usersScreenHeading}
            </Typography>
            <ul style={webStyle.list} data-test-id="userList">
              {users.length > 0 &&
                users.map((user) => (
                  <li
                    data-test-id="userSelectButton"
                    key={user.id}
                    style={webStyle.listItem}
                    onClick={() => this.onSelectUser(user)}
                  >
                    <Typography variant="h6">{user.full_name}</Typography>
                    <Typography variant="subtitle2">{user.email}</Typography>
                  </li>
                ))}
            </ul>
          </>
        )}
      </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    marginTop: "16px",
  },
  inputStyle: {
    margin: "16px 0",
  },
  list: {
    listStyle: "none",
    padding: "0",
    margin: "0",
  },
  listItem: {
    padding: "8px",
    marginBottom: "8px",
    border: "1px solid #CCC",
    cursor: "pointer",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  searchItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  clearButton: {
    padding: "0",
  },
  loadingContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
// Customizable Area End
